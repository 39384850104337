<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Rechercher Tâche"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Réinitialiser le Tri
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'title-asc' },
              }"
            >
              Tri A-Z
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'title-desc' },
              }"
            >
              Tri Z-A
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'assignee' },
              }"
            >
              Tri Assigné
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'due-date' },
              }"
            >
              Tri Deadline
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <div v-for="(task, i) in tasks" :key="i">
            <li
              class="todo-item"
              :class="{ completed: task.is_complete }"
              @click="handleTaskClick(task)"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                class="draggable-task-handle d-inline"
              />
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <b-form-checkbox
                      :checked="task.is_complete"
                      @click.native.stop
                      :disabled="hasSubTasks(task)"
                      @change="updateTaskIsCompleted(task)"
                    />
                    <span class="todo-title">{{ task.title }}</span>
                  </div>
                </div>
                <div class="todo-item-action">
                  <div class="badge-wrapper mr-2">
                    <span v-if="hasSubTasks(task)"
                      >{{ task.sub_tasks.length }} Tâche(s)</span
                    >
                  </div>
                  <div class="badge-wrapper mr-2">
                    <b-badge
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      {{ task.project.code }}
                    </b-badge>
                  </div>
                  <div class="badge-wrapper mr-1">
                    <b-badge
                      v-if="task.status === 'active'"
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      Active
                    </b-badge>
                    <b-badge
                      v-else-if="task.status === 'pending'"
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      En cours (-72h)
                    </b-badge>
                    <b-badge
                      v-else-if="task.status === 'ending'"
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      En cours (-24h)
                    </b-badge>
                    <b-badge
                      v-else-if="task.status === 'done'"
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      Terminée
                    </b-badge>
                    <b-badge
                      v-else
                      pill
                      :variant="`light-${resolveTagVariant(task.status)}`"
                      class="text-capitalize"
                    >
                      ----
                    </b-badge>
                  </div>
                  <small class="text-nowrap text-muted mr-1">{{
                    formatDate(task.due_date, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }}</small>
                  <b-avatar
                    v-if="task.assignee"
                    size="32"
                    :src="task.assignee.avatar"
                    :variant="`light-${resolveAvatarVariant(task.status)}`"
                    :text="avatarText(task.assignee.name)"
                  />
                  <b-avatar v-else size="32" variant="light-secondary">
                    <feather-icon icon="UserIcon" size="16" />
                  </b-avatar>
                </div>
              </div>
            </li>
            <!-- subtasks list start -->
            <div v-if="task.sub_tasks.length > 0" class="pl-4">
              <li
                v-for="subtask in task.sub_tasks" :key="subtask.uid"
                class="todo-item"
                :class="{ completed: subtask.is_complete }"
                @click="handleTaskClick(subtask)"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="draggable-task-handle d-inline"
                />
                <div class="todo-title-wrapper">
                  <div class="todo-title-area">
                    <div class="title-wrapper">
                      <b-form-checkbox
                        :checked="subtask.is_complete"
                        @click.native.stop
                        @change="updateTaskIsCompleted(subtask)"
                      />
                      <span class="todo-title">{{ subtask.title }}</span>
                    </div>
                  </div>
                  <div class="todo-item-action">
                    <div class="badge-wrapper mr-1">
                      <b-badge
                        v-if="subtask.status === 'active'"
                        pill
                        :variant="`light-${resolveTagVariant(subtask.status)}`"
                        class="text-capitalize"
                      >
                        Active
                      </b-badge>
                      <b-badge
                        v-else-if="subtask.status === 'pending'"
                        pill
                        :variant="`light-${resolveTagVariant(subtask.status)}`"
                        class="text-capitalize"
                      >
                        En cours (-72h)
                      </b-badge>
                      <b-badge
                        v-else-if="subtask.status === 'ending'"
                        pill
                        :variant="`light-${resolveTagVariant(subtask.status)}`"
                        class="text-capitalize"
                      >
                        En cours (-24h)
                      </b-badge>
                      <b-badge
                        v-else-if="subtask.status === 'done'"
                        pill
                        :variant="`light-${resolveTagVariant(subtask.status)}`"
                        class="text-capitalize"
                      >
                        Terminée
                      </b-badge>
                      <b-badge
                        v-else
                        pill
                        :variant="`light-${resolveTagVariant(subtask.status)}`"
                        class="text-capitalize"
                      >
                        ----
                      </b-badge>
                    </div>
                    <small class="text-nowrap text-muted mr-1">{{
                      formatDate(subtask.due_date, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}</small>
                    <b-avatar
                      v-if="subtask.assignee"
                      size="32"
                      :src="subtask.assignee.avatar"
                      :variant="`light-${resolveAvatarVariant(subtask.status)}`"
                      :text="avatarText(subtask.assignee.name)"
                    />
                    <b-avatar v-else size="32" variant="light-secondary">
                      <feather-icon icon="UserIcon" size="16" />
                    </b-avatar>
                  </div>
                </div>
              </li>
            </div>
            <!-- subtasks list end -->
          </div>
        </draggable>
        <div class="no-results" :class="{ show: !tasks.length }">
          <h5>Pas de tâche trouvée</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
/* eslint-disable */
import store from "@/store";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { formatDate, avatarText } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import TodoLeftSidebar from "./TodoLeftSidebar.vue";
import TodoTaskHandlerSidebar from "./TodoTaskHandlerSidebar.vue";

export default {
  name: "Todo",
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  setup() {
    const { route, router } = useRouter();
    const routeSortBy = computed(() => route.value.query.sort);
    const routeQuery = computed(() => route.value.query.q);
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks();
    });

    const tasks = ref([]);
    const subTasks = ref([]);
    const hasSubTasks = (task) => task.sub_tasks.length > 0;

    const sortOptions = [
      "latest",
      "title-asc",
      "title-desc",
      "assignee",
      "due-date",
    ];
    const sortBy = ref(routeSortBy.value);
    watch(routeSortBy, (val) => {
      if (sortOptions.includes(val)) sortBy.value = val;
      else sortBy.value = val;
    });
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      delete currentRouteQuery.sort;

      router
        .replace({ name: route.name, query: currentRouteQuery })
        .catch(() => {});
    };

    const blankTask = {
      id: null,
      project: null,
      title: "",
      due_date: new Date(),
      description: "",
      assignee_id: null,
      is_complete: false,
      main_task: null,
      // priority: '',
      // isDeleted: false,
      // is_important: false,
    };
    const task = ref(JSON.parse(JSON.stringify(blankTask)));
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask));
    };

    const addTask = (val) => {
      delete val.id;
      store
        .dispatch("todo/addTask", val)
        .then(() => {
          fetchTasks();
        })
        .catch((e) => {
          // console.log(e)
        });
    };
    const removeTask = () => {
      store.dispatch("todo/destroyTask", { id: task.value.uid }).then(() => {
        fetchTasks();
      });
    };
    const updateTask = (taskData) => {
      store.dispatch("todo/updateTask", { task: taskData }).then(() => {
        fetchTasks();
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const isTaskHandlerSidebarActive = ref(false);

    const taskTags = [
      {
        title: "Active",
        color: "primary",
        route: { name: "todo-status", params: { status: "active" } },
      },
      {
        title: "En cours (-72h)",
        color: "warning",
        route: { name: "todo-status", params: { status: "pending" } },
      },
      {
        title: "En cours (-24h)",
        color: "danger",
        route: { name: "todo-status", params: { status: "ending" } },
      },
      {
        title: "Terminée",
        color: "success",
        route: { name: "todo-status", params: { status: "done" } },
      },
    ];

    const resolveTagVariant = (status) => {
      if (status === "active") return "primary";
      if (status === "pending") return "warning";
      if (status === "ending") return "danger";
      if (status === "done") return "success";
      return "primary";
    };

    const resolveAvatarVariant = (status) => {
      if (status.includes("active")) return "primary";
      if (status.includes("pending")) return "warning";
      if (status.includes("ending")) return "danger";
      if (status.includes("done")) return "success";
      return "primary";
    };

    // Search Query
    const searchQuery = ref(routeQuery.value);
    watch(routeQuery, (val) => {
      searchQuery.value = val;
    });
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks());
    const updateRouteQuery = (val) => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      if (val) currentRouteQuery.q = val;
      else delete currentRouteQuery.q;

      router.replace({ name: route.name, query: currentRouteQuery });
    };

    const fetchTasks = () => {
      store
        .dispatch("todo/fetchTasks", {
          q: searchQuery.value,
          filter: router.currentRoute.params.filter,
          status: router.currentRoute.params.status,
          sortBy: sortBy.value,
        })
        .then((response) => {
          tasks.value = response;
        });
    };

    fetchTasks();

    const handleTaskClick = (taskData) => {
      task.value = taskData;
      task.value.assignee_id = taskData.assignee.uid;
      isTaskHandlerSidebarActive.value = true;
    };

    // Single Task isCompleted update
    const updateTaskIsCompleted = (taskData) => {
      // eslint-disable-next-line no-param-reassign
      taskData.is_complete = !taskData.is_complete;
      updateTask(taskData);
    };

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      task,
      tasks,
      hasSubTasks,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
