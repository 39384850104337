<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <b-button
            v-if="taskLocal.uid"
            size="sm"
            :variant="
              taskLocal.is_complete ? 'outline-success' : 'outline-secondary'
            "
            @click="taskLocal.is_complete = !taskLocal.is_complete"
          >
            {{ taskLocal.is_complete ? "Terminé" : "Marquer comme terminé" }}
          </b-button>
          <h5 v-else class="mb-0">Ajout Tâche</h5>
          <div>
            <feather-icon
              v-show="taskLocal.uid"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-task');
                hide();
              "
            />
            <!-- <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.is_important }"
              @click="taskLocal.is_important = !taskLocal.is_important"
            /> -->
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!--Project -->
            <validation-provider
              #default="validationContext"
              name="project"
              rules="required"
            >
              <b-form-group
                label="Projet"
                label-for="project"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="taskLocal.project"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="projectOptions"
                  :reduce="(option) => option"
                  label="code"
                  id="project"
                  placeholder="Choisir Projet"
                >
                <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pas de résultat pour <em>{{ search }}</em
                  >.
                </template>
                <em v-else style="opacity: 0.5">Recherche un projet...</em>
              </template>
                </v-select>  
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- level -->
            <b-form-radio-group
              v-model="taskLevel"
              :options="taskLevels"
              disabled-field="disabled"
              class="demo-inline-spacing mb-2"
              id="task-level"
              :disabled="taskLocal.uid != undefined"
            />

            <!-- Main task -->
            <validation-provider
              #default="validationContext"
              name="main_task"
              :rules="{ required: taskLevel == 'sub task' }"
            >
              <b-form-group
                v-if="checkSubTask"
                label="Tâche Principale"
                label-for="main_task"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="taskLocal.main_task"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="mainTaskOptions"
                  :reduce="(option) => option.uid"
                  label="title"
                  input-id="main_task"
                  placeholder="Choisir la tâche à associer"
                  :disabled="taskLocal.uid != undefined"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group label="Titre" label-for="task-title">
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Le libellé de la tâche"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Assignee -->
            <b-form-group label="Cessionnaire" label-for="assignee">
              <v-select
                v-model="taskLocal.assignee_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="assigneeOptions"
                :reduce="(option) => option.uid"
                class="assignee-selector"
                input-id="assignee"
                label="name"
              >
                <template #option="{ avatar, name }">
                  <b-avatar size="26" :src="avatar" />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}</span
                  >
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                    :variant="`light-${resolveAvatarVariant('active')}`"
                    :text="avatarText(name)"
                  />

                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}</span
                  >
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un Cessionnaire...</em>
                </template>
              </v-select>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              name="due_date"
              rules="required"
            >
              <b-form-group label="Date Limite" label-for="due-date">
                <flat-pickr v-model="taskLocal.due_date" class="form-control" />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Tag -->
            <!-- <b-form-group label="Priority" label-for="priority">
              <v-select
                v-model="taskLocal.priority"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tagOptions"
                :reduce="(option) => option.value"
                input-id="priority"
              />
            </b-form-group> -->

            <!-- Description -->
            <b-form-group label="Description" label-for="task-description">
              <quill-editor
                id="quil-content"
                v-model="taskLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal.uid ? "Modifier" : "Ajouter " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Réinitialiser
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { computed, ref, toRefs, watch } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import useTaskHandler from "./useTaskHandler";
import store from "@/store";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isTaskHandlerSidebarActive",
    event: "update:is-task-handler-sidebar-active",
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
    };
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetTaskLocal, props.clearTaskData);

    const taskLevels = ref([
        { text: "Principale", value: "principal", disabled: false },
        { text: "Sous Tâche", value: "sub task", disabled: true },
      ]);
    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Décrivez la tâche",
    };

    const taskLevel = ref("principal");
    const isSubTask = ref(false);

    const assigneeOptions = ref([]);
    const projectOptions = ref([]);
    const mainTaskOptions = ref([]);

    const fetchAssignees = () => {
      store.dispatch("user/allUsers").then(users => {
        assigneeOptions.value = users;
      });
    };

    const fetchProjects = () => {
      store.dispatch("project/allProjects").then((projects) => {
        projectOptions.value = projects;
      });
    };

    const fetchTasks = (params) => {
      store.dispatch("todo/allTasks", params).then((tasks) => {
        mainTaskOptions.value = tasks;
      });
    };

    watch(taskLevel, (nl) => {
      if (nl == "sub task") {
        let project = taskLocal.value.project;

        if (project != null) {
          isSubTask.value = true; // show main task fields
          fetchTasks({ project: project.uid });
        } else {
          refFormObserver.value.setErrors({
            project: ["Veuillez sélectionner un projet"],
          });
          isSubTask.value = false; //show main task fields
          nl = "principal";
          taskLocal.value = { ...taskLocal.value, main_task: null };
        }
      } else {
        isSubTask.value = false; //show main task fields
          nl = "principal";
          taskLocal.value = { ...taskLocal.value, main_task: null };
      }
    });

    watch(
      taskLocal,
      (nt) => {
        let level = taskLevels.value.find((l) => l.text == "Sous Tâche");
        if (nt.project != null && level!= null)
          level.disabled = false;
        else
          level.disabled = true;

        if(nt.main_task != null) taskLevel.value = "sub task"
      },
      {
        deep: true,
      }
    );

    watch(props.task, () => {
      fetchAssignees();
      fetchProjects();
    });

    const checkSubTask = computed(() => {
      let project = taskLocal.value.project;
      return project != null && isSubTask.value == true;
    })

    fetchAssignees();
    fetchProjects();

    return {
      // Add New
      taskLevels,
      taskLevel,
      checkSubTask,
      taskLocal,
      onSubmit,
      assigneeOptions,
      projectOptions,
      mainTaskOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
