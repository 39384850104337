import { ref, watch } from '@vue/composition-api'
// import store from '@/store'
export default function useTaskHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
    const resetTaskLocal = () => {
        taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
    }
    watch(props.task, () => {
        resetTaskLocal()
    })

    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })

    const onSubmit = () => {
        const taskData = JSON.parse(JSON.stringify(taskLocal))

        // * If event has id => Edit Event
        // Emit event for add/update event
        if (props.task.value.uid) emit('update-task', taskData.value)
        else emit('add-task', taskData.value)

        // Close sidebar
        emit('update:is-task-handler-sidebar-active', false)
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    // ------------------------------------------------
    // guestOptions
    // ------------------------------------------------

    const resolveAvatarVariant = tags => {
        if (tags.includes('active')) return 'primary';
        if (tags.includes('done')) return 'success';
        if (tags.includes('pending')) return 'warning';
        if (tags.includes('ending')) return 'danger';
        return 'primary';
    };

    const tagOptions = [
        { label: 'Active', value: 'active' },
        { label: 'En Cours (-72h)', value: 'pending' },
        { label: 'En Cours (-24h)', value: 'ending' },
        { label: 'Terminée', value: 'done' },
    ];

    return {
        taskLocal,
        resetTaskLocal,

        // UI
        resolveAvatarVariant,
        tagOptions,
        onSubmit,
    };
}